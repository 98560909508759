<template>
<section id="sub">
    <div class="sub-vis main-banner"><span>POTFOLIO</span></div>
        
    <div class="container breadcrumbs">
        <router-link to="/"><i class="fal fa-home"></i></router-link> > 
        <router-link to="/portfolio/khanstone">시공사례</router-link> > 
        <span>{{this.$route.params.idx}}</span>
    </div>

    <div id="content">
        <dl class="prdt-view">
            <dd>
                <div class="head">
                    <i class="fas fa-angle-left" @click="back_history"></i>
                    <h4>{{detail.product_name}} ({{detail.location}})</h4>
                </div>
            </dd>
        </dl>

        <div id="aboutus" style="">
            <div class="checker">
                <dl>
                    <!-- <dt :style="`background-image: url(${AWSURL + 'portfolio/'+select});`"></dt> -->
                    <dt>
                        <img :src="`${AWSURL + 'portfolio/'+select}`" >
                    </dt>
                </dl>
            </div>
        </div>
        <div class="portfolio-wrap w-100" v-show="detail_img.length > 0">
            <div class="d-flex">
                <ul class="roll">
                    <li v-for="(item,index) in detail_img" :key="index">
                        <a class="link" href="javascript:;" @click="selectIMG(item)">
                            <div class="bg_img bg-main" :style="`background-image: url(${AWSURL + 'portfolio/'+ item})`"></div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        
        <div class="portfolio-wrap w-100">
            <hgroup>
                <h3 style="text-align:left;"></h3>
                <h4></h4>
            </hgroup>

            <div></div>
            <h3 class="type2"><span>그 외 {{detail.e_category}} Portfolio</span><em></em></h3>
            <div class="d-flex">
                <ul class="roll">
                    <li v-for="(item,index) in popular" :key="index">
                        <router-link :to="`/portfolio/${detail.e_category}/${item.no}`" class="link">
                            <div class="bg_img bg-main" :style="`background-image: url(${AWSURL + 'portfolio/'+ item.thumbnail})`"></div>
                            <strong class="mb-30">{{item.product_name}} ({{item.location}})</strong>
                        </router-link>
                    </li>
                </ul>
            </div>

            <div id="sb-footer">
                <div class="center">
                    <router-link :to="`/portfolio/${detail.e_category}`" class="sb-btn type1">목록보기</router-link>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    watch: {
        $route(to, from) {
            if (to.path != from.path) {
                this.select = '';
                this.get_detail()
            }
        } 
    },
    data(){
        return{
            AWSURL: this.$store.state.AWS.URL,
            title:this.$route.params.idx, 
            detail: {},
            popular: [],     
            zoom: 2,
            select: '',
            detail_img: [],
        }
    },
    mounted() {
        var scrollInfo = document.querySelectorAll(".roll");

        for( let i=0; i<scrollInfo.length; i++ ){
            scrollInfo[i].addEventListener('wheel', (evt) => {
                evt.preventDefault();
                scrollInfo[i].scrollLeft += evt.deltaY;
            });
        }

        this.get_detail();
    },
    methods: {     
        get_detail() {            
            const index = this.$route.params.post;
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/portfolio/detail`, {index}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.detail = res.data.info;
                        this.detail_img = res.data.info.img.split(',');
                        this.detail_data_splice();
                        this.popularPortfolio();
                    }
                }
            )
        },
        // popularPortfolio() {         
        //     const p_num = this.$route.params.post;   
        //     const api = this.$store.state.api;            
        //     this.$http.post(`${api}/member/portfolio/similar`, {p_num}
        //     ).then(
        //         res => {
        //             if(res.status == 200) {
        //                 this.popular = res.data.info;
        //             }
        //         }
        //     )
        // },
        popularPortfolio() {
            const product_name = this.detail.product_name;

            const api = this.$store.state.api;
            this.$http.post(`${api}/member/product/portfolio`, { product_name }
            ).then(
                res => {
                    if (res.status == 200) {
                        this.popular = res.data.info;
                    }
                }
            )
        },
        detail_data_splice(){
            if(this.select == '') this.select = this.detail_img[0];
            for(var i=0; i<this.detail_img.length; i++){
                if(this.detail_img[i] == this.select){
                    this.detail_img.splice(i, 1);
                    break;
                }
            }
        },
        selectIMG(item){
            this.select = item;
            this.get_detail();
        },
        back_history() {
            this.$router.go(-1);
        }
    },
}
</script>

<style scoped>
.head em{
    text-transform:uppercase
}
.prdt-tab1{margin-bottom: 40px;}
.prdt-tab1 li{display: inline-block;width: 20%;border: 1px solid #ddd;margin: 0 -1px -1px 0;box-sizing: border-box;}
.prdt-tab1 li a{display: block;text-align: center;font-size: 15px;line-height: 45px;text-decoration: none;color: #000;}
.prdt-tab1 li.active{border-color: #313f64;}
.prdt-tab1 li.active a{background: #313f64;color: #fff;}

.prdt-tab2{border-top: 2px solid #313f64;background: #f7f7f7;border-bottom: 1px solid #ddd;margin-bottom: 40px;text-align: center;padding: 20px 0;}
.prdt-tab2 li{display: inline-block;margin: 5px 10px;}
.prdt-tab2 li a{font-size: 14px;color: #000;}

.prdt-list{margin: -1%;}
.prdt-list li{position: relative;display: inline-block;margin: 1%;width: 23%;vertical-align: top;}
.prdt-list li .link{position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: 2;}
.prdt-list li .tmb{position: relative;padding-bottom: 100%;background-position: center center;background-repeat: no-repeat;background-size: cover !important;}
.prdt-list li .tmb:before{content: '';display: block;position: absolute;top: 0;left: 0;width: 100%;height: 100%;border: 1px solid rgba(0,0,0,0.1);box-sizing: border-box;transition: all 0.1s;}
.prdt-list li:hover .tmb:before{border: 4px solid #313f64;}
.prdt-list li .info{padding: 20px 0;}
.prdt-list li .info > span{display: block;text-align: center;}
.prdt-list li .info .tit{font-size: 18px;color: #222222;letter-spacing: -1px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-weight: 300;}
.prdt-list li .info .txt{font-size: 14px;color: #888888;letter-spacing: -1px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}

.prdt-no-data{margin: 50px 0;text-align: center;font-size: 14px;color: #999;background: #f7f7f7;padding: 40px 0;font-style: italic;}

/* view */
.prdt-view{zoom: 1; width:100%;padding-bottom: 0px;}
.prdt-view dt .bigimg{position: relative;}
.prdt-view dt .bimg {display: block;max-width: 100%;height: auto !important;}
.prdt-view dt .bigimg img{max-width: 100%;max-height:700px;height: auto;}
.prdt-view dt .tmb-list{margin: -0.5%;margin-top: 20px;}
.prdt-view dt .tmb-list li{position: relative;display: inline-block;margin: 0.5%;width: 24%;}
.prdt-view dt .tmb-list li a{position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: 2;}
.prdt-view dt .tmb-list li .tmb{position: relative;padding-bottom: 100%;background-size: cover;background-repeat: no-repeat;background-position: center center;}

.prdt-view dd .head{border-bottom: 1px solid #ddd;padding-bottom: 35px;margin-bottom: 35px;}
.prdt-view dd .head em{display: block;margin-bottom: 20px;font-style: normal;font-size: 17px;color: #505d7e;font-weight: 300;}
.prdt-view dd .head h4{display: block;font-size: 35px;line-height: 42px;color: #222222;letter-spacing: -1px;font-weight: normal;}
.prdt-view dd .txt{display: block;font-size: 16px;line-height: 26px;color: #888888;}
.prdt-view dd .down{margin-top: 40px;}
.prdt-view dd .down a{display: inline-block;margin-right: 2px;background: #313f64;line-height: 35px;padding: 0 20px;text-decoration: none;font-size: 14px;color: #fff;}

.prdt-tab{margin-bottom: 30px;border-bottom: 2px solid #666666;}
.prdt-tab li{position: relative;display: inline-block;vertical-align: top;margin-right: -1px;border: 1px solid #ddd;border-bottom: none;}
.prdt-tab li a{display: block;line-height: 45px;height: 45px;text-align: center;width: 210px;font-size: 16px;color: #999;letter-spacing: -1px;text-decoration: none;box-sizing: border-box;}
.prdt-tab li.active{border: 2px solid #666666;border-bottom: none;background: #fff;height: 47px;margin-bottom: -2px;z-index: 2;}
.prdt-tab li.active a{font-weight: 300;color: #333;}
.prdt-tab li.active a:after{content: '';display: inline-block;background: url(/superboard/images/sb_slt_spr.jpg) no-repeat center center;margin-left: 10px;width: 15px;height: 15px;vertical-align: middle;}

.prdt-tabCont{margin-bottom: 80px;}
.prdt-txtbox img{max-width: 100%;height: auto;}

@media screen and (max-width: 1200px) {

    /* list */
    .prdt-tab1{margin-bottom: 20px;}
    .prdt-tab1 li{width: 33.33%;}
    .prdt-tab1 li a{font-size: 13px;line-height: 35px;}

    .prdt-tab2{margin-bottom: 20px;padding: 15px 0;}
    .prdt-tab2 li{margin: 5px 8px;}
    .prdt-tab2 li a{font-size: 13px;}

    .prdt-list{margin: -1%;margin-top: 15px;}
    .prdt-list li{margin: 1%;width: 48%;}
    .prdt-list li .info{padding: 10px 0;}
    .prdt-list li .info .tit{font-size: 14px;}
    .prdt-list li .info .txt{font-size: 13px;}

    .prdt-no-data{margin: 50px 0;font-size: 13px;padding: 20px 0;}

    /* view */
    .prdt-view{padding-bottom: 40px;}
    .prdt-view > *{float: none;}
    .prdt-view dt{padding-right: 0;width: auto;}
    .prdt-view dt .bigimg{text-align: center;}
    .prdt-view dt .bigimg img{max-height:500px;}
    .prdt-view dt .tmb-list{margin: -0.5%;margin-top: 10px;}

    /* .prdt-view dd{margin-top: 20px;padding-top: 10px;width: auto;} */
    .prdt-view dd .head {padding-bottom: 10px;margin-bottom: 10px;}
    .prdt-view dd .head em{font-size: 13px;margin-bottom: 5px;}
    .prdt-view dd .head h4{font-size: 16px !important;line-height: 20px !important;font-weight: 300;}
    .prdt-view dd .txt{font-size: 13px;line-height: 20px;}
    .prdt-view dd .down{margin-top: 20px;}
    .prdt-view dd .down a{font-size: 13px;}

    .prdt-tab{margin-bottom: 20px;}
    .prdt-tab li{width: 33.33%;box-sizing: border-box;}
    .prdt-tab li a{line-height: 35px;height: 35px;width: auto;font-size: 13px;}
    .prdt-tab li.active{height: 38px;}
    .prdt-tab li.active a:after{margin-left: 5px;width: 10px;height: 10px;background-size: contain;}

    .prdt-tabCont{margin-bottom: 40px;}
    .prdt-txtbox{font-size: 13px;line-height: 19px;}

}
.main-banner{
    background-image: url('../../assets/images/banner/sub_img.png');
}


.portfolio-wrap .d-flex{
    display: flex;
    flex-wrap: nowrap;
}
.portfolio-wrap .side{
    flex-shrink: 0;
    width: 358px;
    /* padding-top: 50px; */
    text-align: left;
}

.portfolio-wrap .side strong{
    display: block;
    margin-bottom: 50px;
    font-family: Noto Sans KR;
    font-weight: 500;
    font-size: 40px;
    color: #000000;
    line-height: 1em;
    text-transform: uppercase;
    
}
.portfolio-wrap .side strong::after{
    content: '';
    margin-top: 20px;
    display: block;
    width: 60px;
    height: 1px;
    background: #000;
    
}
.portfolio-wrap .side a{
    padding: 10px 20px;
    /* border: 2px solid #000;
    color: #000; */
    border: 2px solid #47a6b9;
    color: #47a6b9;
    text-decoration: none;
}
.portfolio-wrap .roll{
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    margin: 0 -10px;
    white-space: nowrap;
    margin-bottom: 30px;
}
.portfolio-wrap .roll li{
    width: 340px;
    flex-shrink: 0;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px;
    position: relative;
    text-align: left;
    cursor: pointer;
}
.portfolio-wrap .roll li strong {
    display: block;
    margin-top: 15px;
    font-size: 20px;
    color: #000000;
    font-weight: 600;
    letter-spacing: -0.02em;
    line-height: 20px;
}
.portfolio-wrap .roll li span {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Noto Sans KR;
    font-weight: 500;
    font-size: 14px;
    color: #999999;
    line-height: 1em;
}
.portfolio-wrap .bg_img{
    width: 100%;
    height: 255px;
}


.bg-cover {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    background: #47a6b9;
    margin-top: 0px !important;
    color: #fff !important;
    font-weight: 600 !important;
}

#aboutus .checker>dl:nth-child(odd)>dd {
    margin-left: 50%;
    padding-left: 30px;
}

#aboutus .checker>dl>dd {
    padding: 10px 0;
    font-size: 16px;
    color: #000000;
    letter-spacing: -0.02em;
    line-height: 26px;
}

#sb-list .list_tbl tr > * {
    position: relative;
    padding: 30px 12px;
    border-style: solid;
    border-color: rgb(221, 222, 224);
    border-image: initial;
    border-width: 0px 1px 1px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.nostyle {
    display: block;
    font-size: 16px;
    line-height: 26px;
    color: #888888;
}

.type2 {
    display: block;
    text-align: center;
    font-family: Noto Sans KR;
    font-weight: 400;
    font-size: 14px;
    padding-left: 2px;
    margin-top: 70px;
    color: #2b2b2b;
    background-image: url(/images/front/sub_obj_line1.jpg);
    background-repeat: no-repeat;
    background-position: 0% 6px;
    position: relative;
    margin-bottom: 20px;
    line-height: 1em;
}

.type2 span {
    padding-left: 8px;
    padding-right: 15px;
    display: inline-block;
    position: relative;
    z-index: 2;
    background-color: #ffffff;
}

.type2 em {
    position: absolute;
    top: 50%;
    right: 0px;
    height: 1px;
    background-color: #d7d7d7;
    width: 99%;
    display: block;
}

/* dt>figure {
    height: 100%;
}

.iiz__img {
    height: 500px !important;
} */

#aboutus .checker>dl>dt {
    position: relative;
    top: 0;
    width: 100%;
    /* height: 700px; */
    /* background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; */
}
#aboutus .checker>dl>dt img{
    width: 100%;
}
@media screen and (max-width: 1200px) {
    #aboutus .checker>dl>dt{
        position: relative;
        width: 100%;
        height: 100%;
        margin-bottom: 20px;
    }

    #aboutus .checker>dl:nth-child(odd)>dd {
        margin-left: 0;
        padding-left: 0;
    }
}

</style>

<style scope>
.head>i{
    position: absolute;
    font-size: 2em;
    color: black;
}

.head>h4{
    margin-left: 3%;
}

@media screen and (max-width: 768px) {
    .iiz__img{
        min-height: 325px;
        object-fit: cover;
    }

    #sub h4 {
        padding-left: 5%;
    }

    .head>i {
        font-size: 22px;
        padding-left: 1%;
    }
}

</style>